.error-main-page{
  min-height: 100vh;
  @include mediaResponsive (sm) {
    min-height: 100%;
  }
}
.errorPage404{
  background-image: url("#{$img_s3_url}images/bg_graphics_2.png");
  background-position: left top;
  background-size: contain;
  @include mediaResponsive (mLg) {
    height: 100vh !important;
  }
}
.bg-404{
  background-image: url("#{$img_s3_url}images/404_Page_1.png") !important;
  background-position: center right !important;
  height: 85% !important;
  background-size: 100% 100% !important;
  min-height: 550px;
  @include mediaResponsive (md) {
    min-height: 400px;
  }
  @include mediaResponsive (sm) {
    min-height: 300px;
  }
}
.bg-went-wrong{
  @extend .bg-404;
  background-image: url("#{$img_s3_url}images/Something_went_wrong.png") !important;
}