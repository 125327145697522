@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Medium.eot');
    src: url('RedHatText-Medium.eot?#iefix') format('embedded-opentype'),
        url('RedHatText-Medium.woff2') format('woff2'),
        url('RedHatText-Medium.woff') format('woff'),
        url('RedHatText-Medium.ttf') format('truetype'),
        url('RedHatText-Medium.svg#RedHatText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Bold.eot');
    src: url('RedHatText-Bold.eot?#iefix') format('embedded-opentype'),
        url('RedHatText-Bold.woff2') format('woff2'),
        url('RedHatText-Bold.woff') format('woff'),
        url('RedHatText-Bold.ttf') format('truetype'),
        url('RedHatText-Bold.svg#RedHatText-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('RedHatText-Regular.eot');
    src: url('RedHatText-Regular.eot?#iefix') format('embedded-opentype'),
        url('RedHatText-Regular.woff2') format('woff2'),
        url('RedHatText-Regular.woff') format('woff'),
        url('RedHatText-Regular.ttf') format('truetype'),
        url('RedHatText-Regular.svg#RedHatText-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

