.custom-header {
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include mediaResponsive (lg) {
    background-color: $white;
  }
  &.sticky {
    background-color: $white;
    position: fixed !important;
    box-shadow: -1px 4px 5px 1px rgba(20, 14, 20, 0.11);
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
  }
  .navbar-brand {
    position: relative;
    img {
      max-width: 100%;
      // width: 80%;
    }
    @include mediaResponsive(sm) {
      width: 50%;
    }
  }
  .btn-primary {
    min-width: 115px;
  }
  .profile-img{
    height: 35px;
    width: 35px;
    margin-left: 10px;
    border-radius: 50%;
    object-fit: cover;
  }
  .dropdown-toggle{
    &::after{
      content: '\f118';
      font-family: Flaticon;
      border: none !important;
      vertical-align: 0;
      font-size: 14px !important;
    }
  }

  .dropdown-menu{
    padding: 0;
    border-radius: 0;
  }

  .nav-dropdown-link{
    padding: 10px 15px;
    display: block;
    border: none !important;
    border-left: 3px solid transparent !important;
    &:hover{
      color: $black;
      border-left: 3px solid $blue !important;
      background-color: rgba($color: $blue, $alpha: .1);
    }
  }
  
  .nav.navbar-nav.login-nav {
    @include mediaResponsive(lg) {
      flex-direction: unset;
      justify-content: center;
    }
  }
}

.navbar-nav-link{
  @extend .font-medium;
  @extend .font-weight-medium;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}