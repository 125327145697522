button,
input,
a {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
a,
a:hover,
button,
button:hover {
    transition: all 0.5s ease 0s !important;
}

a,
button {
    &:hover {
        text-decoration: none !important;
    }
}

.btn-primary {
    @include round_btn_mixin($blue, $white, "normal");
    @include mediaResponsive(sm) {
        max-height: 34px;
    }
}
.blue-border-btn {
    @include round_border_btn_mixin($white, $blue);
}
.search-round-btn {
    @include round_btn_mixin($white, $blue, "big");
    position: absolute;
    bottom: -28px;
    // left: 33%;
    left: 34.5%;
    box-shadow: 0px 6px 5px 0px rgba(20, 14, 20, 0.05);
    @include mediaResponsive(lg) {
        left: 32%;
    }
    @include mediaResponsive(sm) {
        position: relative;
        left: 0;
    }
}
.btn-secondary {
    @include round_btn_mixin($white, $blue, "big");
    position: relative !important;
    bottom: auto !important;
    top: -15px !important;
    margin: 0 auto;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    &:hover, &:active, &:focus{
        border-color: $white !important;
    }
    &:active, &:focus{
        background-color: $blue !important;
    }
    @include mediaResponsive(md) {
        padding: 10px 60px;
    }
}
.arrow-link-btn {
    display: inline-flex;
    align-items: center;
    i {
        position: relative;
        margin-left: 5px;
        left: 0px;
        transition: all 0.3s ease;
        &::before {
            margin-left: 0px;
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        i {
            left: 10px;
        }
    }
    @include mediaResponsive(sm) {
        margin-right: 15px;
    }
}

.back-arrow-link-btn {
    display: inline-flex;
    align-items: center;
    i {
        position: relative;
        left: 0;
        transition: all 0.3s ease;
        &::before {
            margin-left: 0px;
            margin-right: 10px;
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        i {
            left: -10px;
        }
    }
    @include mediaResponsive(sm) {
        margin-left: -5px;
    }
}