.pay-details{
  .login-box{
    
      position: relative;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
      // margin: 20px auto !important;
    
  }
  &~.custom-footer{
    @include mediaResponsive (md) {
      position: relative;
    }
  }
  .select-error{
    margin-top: .6rem;
  }
}