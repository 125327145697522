.dashboard-msg-box{
  max-width: 600px;
  margin: auto;
  padding: 10px 30px;
  box-shadow: 0px 0px 15px 3px rgba(20, 14, 20, 0.11); 
}
.tooltip-error-icon{
  &::before{
    font-size: 14px !important;
  }
}
.min-w-sm-150{
  @include mediaResponsive (sm) {
    min-width: 150px;
  }
}
.action-btn{
  @extend .tooltip-error-icon;
  margin: 0 25px 0 0 !important;;
  &::before{
    margin: 0 !important;
  }
}