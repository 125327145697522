.main_page.bg-graphics{
  background-image: url('#{$img_s3_url}images/bg_graphics.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
.quick-contact-btn{
  position: relative;
  bottom: -1px;
  white-space: nowrap;
  @include mediaResponsive (mLg) {
    left: 40px;
  }
  @include mediaResponsive (md) {
    position: relative;
    bottom: 10px;
    left: auto;
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
}
.preview-quick-contact{
  // left: 32px;
  margin: 0 auto;
  display: block;
}
.profile-img{
  height: auto;
  width: 180px;
  max-width: 100%;
  border-radius: 50%;
  object-fit: cover;
  @include mediaResponsive (md) {
    height: 150px;
    width: 150px;
  }
  @include mediaResponsive (md) {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    display: block;
  }
}
.profile-section{
  .section-title{
    font-size: 50px;
    @include mediaResponsive (lg) {
      font-size: 38px;
    }
    @include mediaResponsive (sm) {
      font-size: 26px;
    }
  }
}
.social-link img{
  height: 25px;
  width: 25px;
}
.profile-map-container{
  height: 100%;
  min-height: 300px;
  max-height: 100vh;
}
.colon-after{
  &::after{
    content: ':';
    right: 20px;
    position: absolute;
    font-weight: 700;
    @include mediaResponsive (lg) {
      right: 0;
    }
    @include mediaResponsive (md) {
      position: relative;
    }
  }
}

// Profile Status
[class*='profile-status-']{
  padding: 4px 12px;
  border-radius: 25px;
  font-weight: 700;
  color: $white;
}
.profile-status{
  &-red{
    background-color: $red;
  }
}
// hide map zoom buttons
.gm-control-active, .gmnoprint{
  display:none ;
}

.currentLocation-banner {
  text-align: center;
  padding-bottom: 20px;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 300px;
    height: auto;
    width: 100%;
  }
}

.currentLocation-banner + .profile-map-container {
  height: 300px;
  max-height: 300px;
  min-height: 200px;
}

//email us button responsivness in profile page 
.email_us_btn{
  // max-width: 130px !important; 
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 212px !important;
  }
 
  @media screen and (max-width: 760px) {
    margin: auto !important;
    margin-top: 10px;
  }
  @include mediaResponsive (mLg) {
    left: 40px;
  }
}

.banner_img{
  object-fit: contain;
  margin:auto;
  width: 100%;
}

.links_btn_div{
  @media screen and (max-width: 767px) {
    align-items: center !important; 
  }
}
//Banner Model Contact Buttons
.contact_btns{
  width: 30%;
  @media screen and(max-width:988px) {
    width: 100%;
  }
}