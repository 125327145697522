.dropdown-select__arrow {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,eeeeee+100 */
  background: #ffffff; /* Old browsers */
  display: inline-block;
  width: 25px;
  height: 36px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;

  .dropdown-select__arrow-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    &::before{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      margin: 0;
    }

  }

  .arrow-down {
    // border-top: 5px solid gray;
    &::before{
      margin: 0;
    }
  }

  .arrow-up {
    // border-bottom: 5px solid gray;
    &::before{
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }
}
