.dropdown-select {
  position: relative;
  width: 100%;
  border: none;
  border-bottom: 1px solid $border-grey;
  border-radius: 0;

  &--focused{
    border-bottom: 1px solid $blue;
  }

  .dropdown-select__container {
    @import './input';
    @import './arrow';
    @import './select_deselect_all';
    @import './spinner';
  }
  @import './options';
}

// .dropdown-select--focused {
//   border-color: #80bdff;
// }
