.practitioner-carousel{
  .owl-nav{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    @media (min-width: 821px) {
      width: 60%;
    }@media (max-width: 820px) {
      width: 100%;
    }
    margin-top: 0 !important;
    [class*='owl-']{
      position: absolute;
      height: 25px;
      width: 25px;
      border-radius: 50% !important;
      border: 2px solid #00000000 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        color: $black !important;
        background-color: $white !important;
      }
      span{
        height: 15px;
        width: 15px;
        top: -2px;
        position: relative;
        font-weight: 700;
        &:hover{
          color: $black !important;
          background-color: transparent !important;
        }
      }
    }
    .owl-prev{
      position: absolute;
      left: 0;
    }
    .owl-next{
      position: absolute;
      right: 0;
    }
  }
}



.carousel-slider{
  .control-dots,
  .carousel-status{
    display: none;
  }
  button.control-arrow{
    // height: 25px;
    // width: 25px;
    border-radius: 50%;
    opacity: 1 !important;
    position: absolute;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    // animation: 1.6s ease-out 0s infinite normal none running playShadow;
    // animation: playShadow 1.6s ease-out 0s infinite none;
    animation-name: playShadow;
    animation-duration: 1.6s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-play-state: running;

    &:hover{
      background-color: transparent !important;
    }
    &::before{
      font-family: Flaticon;
      color: $blue;
      display: block;
      border: none;
      font-size: 26px;
      border-radius: 50% 50% 50% 50%;
      border: none !important;
      // transform: rotate(30deg);
      // animation: 1.6s infinite bounceLeft;
      width: -webkit-fit-content;
      
      &::before{
        content: "\f10f";
        // animation: 2s infinite bounceLeft;
      }
    }
    &.control-next{
      right: 15px !important;
      height: initial;
      width: -webkit-fit-content;
      &::before{
        content: "\f10e";
        // animation:  2s infinite bounceLeft;
      }
    }
  }
}
//   }
  // 0%, 20%, 50%, 80%, 100% {
  //   transform: translateX(0);
  // }
  // 40% {
  //   transform: translateX(30px);
  // }
  // 60% {
  //   transform: translateX(15px);
  // }
// }

// @keyframes bounceRight {
//   0%, 20%, 50%, 80%,100% {
//     transform: translateX(0);
//   }
//   40% {
//     transform: translateX(-30px);
//   }
//   60% {
//     transform: translateX(-15px);
//   }
// }

// @keyframes glow{
//   to {
//     box-shadow: 0px 10px 20px 10px $blue;
//   }
// }

@keyframes shadow-pulse-blue
{
  0% {
    box-shadow: 0 0 0 0px rgba(52, 108, 252, 0.5);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(52, 108, 252, 0);
  }
}

.carousel .slider-wrapper {
  padding-left: 25px !important;
}