.custom-footer {
  background-color: $white;
  position: relative;
  z-index: 999;
  .footer-link {
    display: block;
    position: relative;
    color: $black;
    &:before {
      content: "|";
      color: $black !important;
      position: relative;
      left: -5px;
      top: -1px;
      margin-left: 5px;
      padding-left: 5px;
      @include mediaResponsive(md) {
        display: none;
      }
    }
  }
  .footer-social-icons {
    display: inline-block;
    margin: 2px 0px 5px 7px;
    img {
      height: 25px;
      width: 25px;
    }
  }
}
