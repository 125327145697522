.table-responsive{
  .table-head{
    background-color: rgba($color: $blue, $alpha: .1) !important;
    p {
      text-align: center;
    }
  }
  .dataTables_paginate{
    float: none;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    span{
      .paginate_button{
        border-radius: 50% !important;
        padding: 0 !important;
        height: 30px !important;
        width: 30px !important;
        min-width: 30px !important;
        margin-left: 5px !important;
        margin-right: 5px;
        font-weight: 500;
        @include mediaResponsive (sm) {
          height: 25px !important;
          width: 25px !important;
          min-width: 25px !important;
        }
        &.current{
          background-color: $blue !important;
          color: $white !important;
        }
      }
    }
    .paginate_button{
      &.previous{
        &::before{
          content: "\f11a";
          margin-right: 10px;
        }
      }
      &.next{
        &::after{
          content: "\f100";
          margin-left: 10px;
        }
      }
      &.previous,
      &.next{
        font-weight: 500;
        &::before,
        &::after{
          position: relative;
          font-family: Flaticon;
          font-size: 20px;
          top: 3px;
        }
        &:hover{
          background-color: transparent !important;
        }
      }
    }
  }
}
td.dtr-control{
  &::before{
    position: relative !important;
    top: 8px;
  }
}