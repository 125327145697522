.terms-banner-section {
  // min-height: 100vh;
  &.vh-lg-100{
    @include mediaResponsive (mLg) {
      height: 100%;
    }
    
  }
  @extend .common-banner-section;
  .terms-banner-img {
    @extend .common-banner-img;
    img {
      width: 40%;
      object-fit: cover;
    }
    @include mediaResponsive(xxxxl){
      background-size: 69% 90%;
    }
  }
  .section-spacer-big{
    @include mediaResponsive (sm) {
      padding: 90px 0;
    }
  }
}
