.hamburger {
  background-color: transparent;
  border: none !important;
  .line {
    width: 30px;
    height: 2px;
    background-color: $blue;
    display: block;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @include mediaResponsive(sm) {
      height: 2px;
      width: 30px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &.open {
    .line:nth-child(1) {
      -webkit-transform: translateY(3px) rotate(45deg);
      -ms-transform: translateY(3px) rotate(45deg);
      -o-transform: translateY(3px) rotate(45deg);
      transform: translateY(3px) rotate(45deg);
    }
    .line:nth-child(2) {
      opacity: 0;
    }
    .line:nth-child(3) {
      -webkit-transform: translateY(-13px) rotate(-45deg);
      -ms-transform: translateY(-13px) rotate(-45deg);
      -o-transform: translateY(-13px) rotate(-45deg);
      transform: translateY(-13px) rotate(-45deg);
    }
  }
}

.navbar-collapse.show {
  @include mediaResponsive (lg) {
    background-color: #fff;
    height: calc(100vh - 86px);
  }
  @include mediaResponsive(sm) {
    height: calc(100vh - 57.31px);
  }
}
