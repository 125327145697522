.modal {
  &-content {
    border-radius: 0;
  }
}


.modal, .forgot-modal {
  .modal-close-btn {
    position: absolute;
    right: 10px;
    top: 0;
    &::before {
      font-size: 10px;
      margin-left: 0;
      font-weight: 700;
    }
  }
  .modal-header {
    padding: 8px 1rem;
  }
  &-img {
    max-width: 200px;
    @include mediaResponsive(md) {
      margin: 0 auto;
      display: block;
      max-width: 150px;
    }
  }
}

.image-croper-modal{
  .modal-body{
    padding: 0 !important;
  }
  .ReactCrop__crop-selection{
    @include mediaResponsive(sm){
      max-width: 250px !important;
      max-height: 250px !important;
    }
  }
  .modal-dialog-centered.modal-dialog{
    max-height: 90vh !important;
    // min-height: auto;
  }
  .ReactCrop__image{
    max-height: calc(100vh - 200px);
  }
}

.modal{
  z-index: 999999;
}
.modal-backdrop,
.swal2-container{
  z-index: 999999 !important;
}
// .ReactCrop.center-crop-div.ReactCrop--fixed-aspect{
//   .ReactCrop__crop-selection{
//     top: 50%;
//     left: 50%;
//     transform: translate(100%, 100%);
//   }
// }