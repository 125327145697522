.dropdown-select__select-deselect-all {
  position: absolute;
  top: 23%;
  right: 35px;

  input {
    width: 20px;
    height: 20px;
  }
}
