.dropdown-select__input,
.dropdown-select__input:focus {
  border: none;
  outline: none;
}

.dropdown-select__input {
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  font-size: 1rem;
  padding: 6px 37px 6px 0px;
  border-radius: 0.25rem;
  @include mediaResponsive (sm) {
    font-size: 14px;
  }
}
input.dropdown-select__input::placeholder{
  font-size: 14px;
}
