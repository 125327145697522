.dropdown-select__options__option {
  padding: 6px 6px 6px 20px;
  .custom-control{
    padding-left: 10px;
  }
  cursor: pointer;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 15px;
    width: 15px;
    
  }
}

.dropdown-select__options__option:hover,
.dropdown-select__options__option--focused {
  background-color: rgba($blue, .1);
  color: $black;
}
