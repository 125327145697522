    /*
    Flaticon icon font: Flaticon
    Creation date: 12/03/2021 13:20
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-right-arrow:before { content: "\f100"; }
.flaticon-view:before { content: "\f101"; }
.flaticon-private:before { content: "\f102"; }
.flaticon-money:before { content: "\f103"; }
.flaticon-checklist:before { content: "\f104"; }
.flaticon-consultation:before { content: "\f105"; }
.flaticon-network:before { content: "\f106"; }
.flaticon-image:before { content: "\f107"; }
.flaticon-calendar:before { content: "\f108"; }
.flaticon-phone-call:before { content: "\f109"; }
.flaticon-email:before { content: "\f10a"; }
.flaticon-location:before { content: "\f10b"; }
.flaticon-right-arrow-1:before { content: "\f10c"; }
.flaticon-left-arrow:before { content: "\f10d"; }
.flaticon-next:before { content: "\f10e"; }
.flaticon-previous:before { content: "\f10f"; }
.flaticon-cancel:before { content: "\f110"; }
.flaticon-check:before { content: "\f111"; }
.flaticon-checked:before { content: "\f112"; }
.flaticon-close-button:before { content: "\f113"; }
.flaticon-round-error-symbol:before { content: "\f114"; }
.flaticon-facebook-app-symbol:before { content: "\f115"; }
.flaticon-youtube:before { content: "\f116"; }
.flaticon-location-pin:before { content: "\f117"; }
.flaticon-down-arrow:before { content: "\f118"; }
.flaticon-cloud-computing:before { content: "\f119"; }
.flaticon-left-arrow-1:before { content: "\f11a"; }
.flaticon-information:before { content: "\f11b"; }
.flaticon-add-button:before { content: "\f11c"; }
.flaticon-camera:before { content: "\f11d"; }
.flaticon-photo-camera:before { content: "\f11e"; }
.flaticon-recycling-bin:before { content: "\f11f"; }
.flaticon-right-arrow-2:before { content: "\f120"; }
.flaticon-list:before { content: "\f121"; }
.flaticon-map:before { content: "\f122"; }
    
    $font-Flaticon-right-arrow: "\f100";
    $font-Flaticon-view: "\f101";
    $font-Flaticon-private: "\f102";
    $font-Flaticon-money: "\f103";
    $font-Flaticon-checklist: "\f104";
    $font-Flaticon-consultation: "\f105";
    $font-Flaticon-network: "\f106";
    $font-Flaticon-image: "\f107";
    $font-Flaticon-calendar: "\f108";
    $font-Flaticon-phone-call: "\f109";
    $font-Flaticon-email: "\f10a";
    $font-Flaticon-location: "\f10b";
    $font-Flaticon-right-arrow-1: "\f10c";
    $font-Flaticon-left-arrow: "\f10d";
    $font-Flaticon-next: "\f10e";
    $font-Flaticon-previous: "\f10f";
    $font-Flaticon-cancel: "\f110";
    $font-Flaticon-check: "\f111";
    $font-Flaticon-checked: "\f112";
    $font-Flaticon-close-button: "\f113";
    $font-Flaticon-round-error-symbol: "\f114";
    $font-Flaticon-facebook-app-symbol: "\f115";
    $font-Flaticon-youtube: "\f116";
    $font-Flaticon-location-pin: "\f117";
    $font-Flaticon-down-arrow: "\f118";
    $font-Flaticon-cloud-computing: "\f119";
    $font-Flaticon-left-arrow-1: "\f11a";
    $font-Flaticon-information: "\f11b";
    $font-Flaticon-add-button: "\f11c";
    $font-Flaticon-camera: "\f11d";
    $font-Flaticon-photo-camera: "\f11e";
    $font-Flaticon-recycling-bin: "\f11f";
    $font-Flaticon-right-arrow-2: "\f120";
    $font-Flaticon-list: "\f121";
    $font-Flaticon-map: "\f122";