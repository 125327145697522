$landingBlue: #364cfc;
$faqGray: #f2f2f2;
@mixin bannerBG {
  background-image: url("#{$img_s3_url}images/Home_page\,Privacy_policy___terms___conditions_bg_graphics.png");
  background-repeat: no-repeat;
}

.landingPage {
  .font-medium:not(.navbar-nav-link) {
    font-size: 16px;
    @include mediaResponsive(md) {
      font-size: 16px;
    }
    @include mediaResponsive(sm) {
      font-size: 14px;
    }
  }
  
  @include mediaResponsive(xxl) {
    @include bannerBG();  
  }
  @include mediaResponsive(xl) {
    @include bannerBG();
  }
  @include mediaResponsive(lg) {
    @include bannerBG();
  }
  @include mediaResponsive(md) {
    @include bannerBG();
  }
  @include mediaResponsive(sm) {
    background-image: none;
    #root{
      @include bannerBG();
      background-size: contain;
      background-position: right top;
    }
  }
  background-size: contain;
  background-position: right top;
  .banner-sign-up-btn {
    @include round_btn_mixin($landingBlue, $white, "big");
    font-size: 18px !important;
    padding: 12px 35px !important;
    // font-weight: 500;
    min-width: 200px;
    @include mediaResponsive(md) {
      min-width: 100%;
    }
  }
  .how-it-works {
    background-image: none !important;
  }
  .reverse-banner-img {
    position: relative;
    &::before {
      @include bannerBG();
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      transform: rotate(180deg);
      background-size: 100% 100%;
      background-position: left bottom;
    }
  }
  .why-choose {
    position: relative;
    &::before {
      @include bannerBG();
      content: "";
      position: absolute;
      height: 50%;
      width: 100%;
      top: 0;
      left: 0;
      transform: rotateY(180deg);
      background-size: 100% 96%;
      background-position: 0px 25px;
    }
    .font-title {
      font-size: 24;
      @include mediaResponsive(md) {
        font-size: 20px;
      }
      @include mediaResponsive(sm) {
        font-size: 16px;
      }
    }
  }
  .faq-section {
    background-color: $faqGray;
  }
  .search-section {
    .section-title {
      font-size: 50px;
      @include mediaResponsive(md) {
        font-size: 30px;
      }
      @include mediaResponsive(sm) {
        font-size: 24px;
      }
    }
  }
  @include mediaResponsive(md) {
    .landing-main-img {
      display: none;
    }
  }
}

.landing-blue-search-section {
  position: relative;
  padding: 40px 40px 50px;
  margin: 0 auto;
  background-image: url("#{$img_s3_url}images/Call_to_action_bg.jpg");
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
  // background-color: $blue;
  color: $white;
  @include mediaResponsive(lg) {
    width: auto;
  }
  @include mediaResponsive(md) {
    width: auto;
  }
  @include mediaResponsive(sm) {
    padding-bottom: 5px;
  }
}

.join-linp-section{
  .container{
    position: relative;
    &::before{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      height: 1px;
      width: 100%;
      background-color: rgba($color: $landingBlue, $alpha: .2);
    }
  }
}

.accordion {
  .question-heading{
    margin: 1.5rem 0 1.8rem 0 !important;
    &:last-child{
      margin-bottom: 0 !important;
    }
    .collapse{
      margin-top: 1rem;
    }
  }
  .que {
    padding: 0 0 0 35px;
    position: relative;
    display: block;
  }
  .card-body {
    padding: 0 0 0 35px;
  }
  @include mediaResponsive(md) {
    .que {
      display: block;
    }
  }
}

.accordion .que:after {
  font-family: "icomoon";
  content: "\ed5b"; /* Minus */
  color: $landingBlue;
  position: absolute;
  top: 0;
  left: 0;
}

.accordion .que.collapsed:after {
  content: "\ed5a"; /* Plus */
}

.line-section {
  background-image: url("#{$img_s3_url}images/bgLine.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  @include mediaResponsive(lg) {
    background-image: none;
  }
  .box-1 {
    position: relative;
    top: -50px;
    .what-we-do-icons {
      background-image: url("#{$img_s3_url}images/SignUpIcon.png") !important;
    }
    @media (min-width: 1920px) {
      top: -20px;
    }
    @media (min-width: 2500px) {
      top: 30px;
    }
  }
  .box-2 {
    position: relative;
    top: 5px;
    .what-we-do-icons {
      background-image: url("#{$img_s3_url}images/MagnifyingGlassIcon.png") !important;
    }
    @media (min-width: 1920px) {
      top: 35px;
    }
    @media (min-width: 2500px) {
      top: 80px;
    }
  }
  .box-3 {
    position: relative;
    top: 0px;
    .what-we-do-icons {
      background-image: url("#{$img_s3_url}images/PromotedIcon.png") !important;
    }
    @media (min-width: 1920px) {
      top: 25px;
    }
    @media (min-width: 2500px) {
      top: 80px;
    }
  }
  .box-4 {
    position: relative;
    top: -60px;
    .what-we-do-icons {
      background-image: url("#{$img_s3_url}images/BarChartIcon.png") !important;
    }
    @media (min-width: 1920px) {
      top: -40px;
    }
    @media (min-width: 2500px) {
      top: 15px;
    }
  }
  @include mediaResponsive(lg) {
    .box-1,
    .box-2,
    .box-3,
    .box-4 {
      top: 0px;
    }
  }
  @include mediaResponsive(md) {
    .box-1,
    .box-2,
    .box-3,
    .box-4 {
      top: 0px;
      display: flex;
      align-items: flex-start;
      .what-we-do-icons {
        min-height: 75px;
        min-width: 75px;
        max-width: 75px;
        background-size: contain;
      }
    }
  }
  p {
    font-size: 16px;
    @include mediaResponsive(sm) {
      font-size: 14px;
    }
  }
}

.promotional-banner-section {
  @include mediaResponsive(mLg) {
    max-height: 500px;
    margin: 0 auto !important;
    display: block;
  }
}