@mixin round_btn_mixin($bg-color, $color, $size) {
  @if $size == "normal" {
    padding: 3px 25px;
    min-width: 130px;
  } @else if $size == "big" {
    padding: 8px 40px;
    min-width: 150px;
    font-size: 18px;
    @include mediaResponsive(md) {
      min-width: auto;
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  background-color: $bg-color;
  color: $color;
  font-weight: 700;
  text-align: center;
  border-radius: 40px;
  border: 2px solid $bg-color;
  transition: all 0.3s ease;
  display: inline-block;
  &:hover {
    background-color: $color;
    color: $bg-color;
    text-decoration: none;
  }
}

@mixin round_border_btn_mixin($bg-color, $color) {
  font-size: 16px;
  padding: 3px 15px;
  min-width: 110px;
  background-color: $bg-color;
  border: 2px solid $color;
  color: $color;
  font-weight: 700;
  text-align: center;
  border-radius: 40px;
  border: 2px solid $color;
  transition: all 0.3s ease;
  display: inline-block;
  &:hover {
    background-color: $color;
    color: $bg-color;
    text-decoration: none;
  }
}

// responsive mixin
@mixin mediaResponsive($breakpoint) {
  @if $breakpoint == "xxxxl" {
    @media (min-width: 1500px) {
      @content;
    }
  } @else if $breakpoint == "xxxl" {
    @media (min-width: 1366px) {
      @content;
    }
  } @else if $breakpoint == "xxl" {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "mLg" {
    @media only screen and (min-width: 992px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "sm" {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $breakpoint == "xs" {
    @media (max-width: px) {
      @content;
    }
  }
}
