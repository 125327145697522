.change-password{
  background-image: url('#{$img_s3_url}images/Change_pwd___payment_detail_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 94vh;
  @include mediaResponsive (lg){
    background-size: cover;
    background-position: right;
    padding-bottom: 75px !important;
  }
  @include mediaResponsive (sm){
    background-image: none;
    min-height: 84vh;
  }
  .login-box{
    min-width: 450px;
    max-width: 500px;
    position: relative;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    // margin: 50px 0 50px;
    // @include mediaResponsive (lg) {
    //   margin: 50px 0 100px;
    // }
    @include mediaResponsive(md){
      min-width: 50%;
      position: relative;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
      margin: 20px auto !important;
      margin: 50px 0 150px;
    }
    @include mediaResponsive (sm){
      width: 90%;
      margin: 60px 0 130px;
    }
  }
  &~.custom-footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    @include mediaResponsive (md) {
      position: relative;
      z-index: auto;
    }
  }
  &.quick-contact{
    .login-box{
      @include mediaResponsive (mLg) {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin: 50px auto;
        transform: none;
      }
    }
  }
}
