.form-control{
    border-radius: 0;
    border: none !important;
    border-bottom: 1px solid $border_grey !important;
    padding-left: 0;
    padding-right: 0;
    &:focus{
        box-shadow: none !important;
        border-bottom: 1px solid $blue !important;
    }
    &::placeholder{
        color: $black;
    }
    &.shown-placeholder{
        &::placeholder{
            color: $grey;
        }   
    }
    &:disabled, &:read-only,
    &.practitionersReadOnly{
        background-color: $white;
        color: rgba($color: $black, $alpha: .5);
    }
}

label{
    font-size: 14px;
}


// custom-select drop down changes
.css-yk16xz-control{
    box-shadow: none;
    border: none !important;
    border-radius: none !important;
    border-bottom: 1px solid $border_grey !important;
}
.css-1pahdxg-control{
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid $border_grey !important;
}
.css-tj5bde-Svg{
    color: $black;
}
.css-1okebmr-indicatorSeparator{
    display: none;
}
.css-1hwfws3,
.css-g1d714-ValueContainer{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.business-profile-select {
    .css-yk16xz-control,
    .css-1pahdxg-control  {
        top: -6px;
    }
    div[class$="indicatorContainer"]{
        position: relative;
        width: 25px;
        svg{
            display: none;
        }
        &::before{
            content: "\f118";
            font-family: Flaticon;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            color: $black;
            right: 5px;
        }
    }
    div[class$="-MenuList"]{
        z-index: 9999 !important;
        max-height: 200px !important;
    }
    .css-11unzgr{
        z-index: 9999 !important;
        max-height: 200px !important;
    }
    
}
.css-1wa3eu0-placeholder{
    color: $black !important;
    font-size: 14px;
    @include mediaResponsive (lg) {
        font-size: 13px;
    }
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer{
    padding-right: 0 !important;
}
.css-26l3qy-menu{
    z-index: 9999 !important;
    display: block !important;
    position: relative !important;
    top: 0 !important;
}
.form-control.css-2b097c-container{
    min-width: 100px;
}

// floating labels
input, 
textarea, 
.form-control{
    &:placeholder-shown{
        &+ .form-control-placeholder{
            top: 10px;
            color: $black;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0;
            span.text-blue{
                position: absolute;
                top: -5px;
                left: auto;
            }
        }
    }
    &+ .form-control-placeholder,
    &:focus + .form-control-placeholder{
        position: absolute;
        transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        top:-14px;
        font-size: 14px;
        margin-left: 0;
        color: $blue;
        font-weight: 400;
        width: initial;
        padding-right: 10px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span.text-blue{
            position: absolute;
            top: -5px;
            left: auto;
        }
    }
}
.select-input.form-control{
    border-bottom: 1px solid transparent !important;
}

textarea{
    resize: vertical;
    min-height: 100px !important;
    
    &.form-control + .form-control-placeholder,
    &+ .form-control-placeholder {
        top: -22px;
    }
    &:focus + .form-control-placeholder{
        top: -22px !important;
    }
}

.form-group{
    position: relative;
}

// range slider
.input-range__track.input-range__track--background{
    width: 80%;
    left: 8%;
    @include mediaResponsive (sm) {
        left: 8%;
    }
}
.input-range__label--min, 
.input-range__label--max{
    bottom: 0 !important;
    color: #58a2d8 !important;
    font-family: 'Red Hat Text' !important;
}
.input-range__slider{
    background-color: $white !important;
    border-color: #58a2d8 !important;
    z-index: 1;
}
.input-range__track--active{
    background-color: $blue !important;
}
.input-range__label{
    &--min{
        left: 5px !important;
        @include mediaResponsive(sm) {
            left: 6px !important;
        }
    }
    &--max{
        right: 10px !important;
        @include mediaResponsive (sm) {
            right: 15px !important;
        }
    }
    &--value{
        top: -33px !important;
        &::after{
            content: '';
            height: 9px;
            width: 9px;
            position: absolute;
            display: block;
            bottom: -2px;
            left: -5px;
            transform: rotateZ(45deg) !important;
            background-color: $blue;
            transform: rotateY(45deg);
            z-index: -1;
        }
    }
}
.input-range__slider-container {
    .input-range__label-container {
        font-family: 'Red Hat Text' !important;
        background-color: $blue !important;
        padding: 2px 3px;
        border-radius: 2px;
        color: $white !important;
        font-size: 11px;
    }
}

// Flat picker calender
.flatpickr-calendar{
    &.arrowTop{
        &::before, &::after{
        border-bottom-color: $blue !important;
        }
    }
    &.arrowBottom{
        &::before, &::after{
            border-top-color: $blue !important;
        }
    }
}

.form-control.flatpickr-input{
    background-color: $white;
}
.flatpickr-months, 
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month,
.flatpickr-monthDropdown-months{
    background: $blue !important;
    background-color: $blue !important;
    color: $white !important;
}
.flatpickr-months, 
.flatpickr-months .flatpickr-month{
    border-radius: 5px 5px 0 0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{
    background-color: $blue !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{
    font-size: 14px !important;
}

.input-calender-icon{
    position: absolute;
    top: 0;
    right: 00px;
    &::before{
        margin-left: 0px;
    }
}
.css-g1d714-ValueContainer{
    @include mediaResponsive (lg) {
        padding: 0 !important;
    }
}


// custom checkbox
.custom-checkbox+.custom-control-input:checked~.custom-control-label::after{
    background-image: none;
    content:"\f111";
    font-family: Flaticon;
    font-size: 10px;
    font-style: normal;
    left: -1.55rem;
    top:.25rem;
}
.custom-checkbox+.custom-control-input:checked~.custom-control-label::before{
    background-color: $blue;
    border: 2px solid $blue;
}
.custom-control-input:focus ~ .custom-control-label::before{
    box-shadow: none;
}
.custom-checkbox .custom-control-label::before{
    border-radius: 1px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background-size: 9px;
}


// text-transform multi-select 
input.dropdown-select__input::placeholder {
    text-transform: capitalize;
}

// autocomplete
.autocomplete-dropdown-container{
    border: none !important;
}

.dropdown-select__options-open .custom-checkbox label::before{
    border-radius: 50%;
}

/**
    google place auto suggest 
*/

.pac{
    &-logo{
      &::after{
        display: none;
      }
    }
    &-item {
      padding: 4px;
      border-left: 2px solid transparent;
      &:hover, &.pac-item-selected{
        border-left: 2px solid $blue;
        background-color: rgba(52, 108, 252, 0.1);
      }
      &-query{
        color: $black !important;
      }
      span{
        color: $blue;
      }
    }
    &-matched{
      color: $black !important;
    }
  }

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}